import { client, parsers } from "@passwordless-id/webauthn";
import toast from "react-hot-toast";

export const registerPasswordLess = async (
  event,
  __BaseURL,
  navigate,
  founder
) => {
  const form = event.target;
  const requestURLBase = `${__BaseURL}founders/${founder}`;

  try {
    if (!client.isAvailable()) {
      throw new Error("WebAuthn is not supported in this browser");
    }

    if (!form.userName.value) {
      throw new Error("Some Required Fields are Empty!");
    }

    const challengeRes = await fetch(
      `${requestURLBase}/get-challenge?email=${form.userEmail.value}&name=${form.userName.value}&organization=${form.organizationName.value}`,
      {}
    );
    const challengeResData = await challengeRes.json();

    if (!challengeRes.ok) {
      throw new Error(challengeResData.message);
    }

    const { challenge, userHandle } = challengeResData;
    const registrationOptions = {
      authenticatorType: "both",
      userVerification: "required",
      timeout: 60000,
      attestation: false,
      userHandle: userHandle,
      debug: false,
    };

    const registration = await client.register(
      form.userName.value,
      challenge,
      registrationOptions
    );

    const parsed = parsers.parseRegistration(registration);
    console.info("Parsed authentication: ", parsed);

    if (!registration) {
      throw new Error("Registration Failed!");
    }

    const res = await fetch(`${requestURLBase}/verify-registration`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ registration }),
    });
    const data = await res.json();
    console.log(
      "🚀 ~ file: authentications.js:53 ~ registerPasswordLess ~ data:",
      data
    );

    if (!res.ok) {
      throw new Error(data.message);
    }

    toast.success("Successfully Register");
    navigate(`/${founder}/mint`, {
      state: {
        login: {
          userName: form.userName.value,
          credentialID: data.message.credential.id,
        },
      },
    });

    form.reset();
  } catch (err) {
    toast.error(String(err));
    console.error("~ authentications.js ~ registerPasswordLess(): " + err);
  }
};

export const loginPasswordLess = async (
  event,
  __BaseURL,
  navigate,
  founder
) => {
  const form = event.target;
  const requestURLBase = `${__BaseURL}founders/${founder}`;

  try {
    if (!client.isAvailable()) {
      throw new Error("WebAuthn is not supported in this browser");
    }

    if (!form.userName.value) {
      throw new Error("Username is Required!");
    }

    const res = await fetch(`${requestURLBase}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: form.userName.value }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(data.message);
    }

    const challenge = data.challenge;
    console.log(
      "🚀 ~ file: authentications.js:90 ~ loginPasswordLess ~ challenge:",
      challenge
    );
    console.log("data.message.credentialID", data);
    const authentication = await client.authenticate(
      [data.message.credentialID],
      challenge,
      {
        authenticatorType: "auto",
        userVerification: "required",
        timeout: 60000,
      }
    );
    console.log(
      "🚀 ~ file: authentications.js:108 ~ loginPasswordLess ~ authentication:",
      authentication
    );

    const parsed = parsers.parseAuthentication(authentication);
    console.info("Parsed authentication: ", parsed);

    if (!authentication) {
      throw new Error("Oh no, something went wrong! Response");
    }

    const verifyRes = await fetch(`${requestURLBase}/verify-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ authentication }),
    });
    const verifyResData = await verifyRes.json();

    if (!verifyRes.ok) {
      throw new Error(verifyResData.message);
    }
    toast.success("Success!");
    navigate(`/${founder}/mint`, {
      state: {
        login: {
          userName: form.userName.value,
          credentialID: authentication.credentialId,
        },
      },
    });

    form.reset();
  } catch (err) {
    toast.error(String(err));
    console.error("~ authentications.ts ~ loginPasswordLess(): " + err);
  }
};
