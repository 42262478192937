import { useEnv } from "../hooks";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (emailValue, setError) => {
  if (!emailValue) {
    setError({
      status: true,
      message: "Email is required.",
    });
    return;
  }

  if (!emailValue.match(emailRegex)) {
    setError({
      status: true,
      message: "Please enter a valid email.",
    });
    return;
  }

  setError({
    status: false,
    message: "",
  });
};

export const lowercaseCharactersValidator = (value) => {
  for (const char of value) {
    if (!((char.charCodeAt(0) >= 97 && char.charCodeAt(0) <= 122) || (char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57))) {
      return false;
    }
  }

  return true;
};

export const validateUsername = (usernameValue, setError) => {
  if (!usernameValue) {
    setError({
      status: true,
      message: "Username is required.",
    });
    return;
  }

  // if (usernameValue[0].charCodeAt(0) >= 48 && usernameValue[0].charCodeAt(0) <= 57) {
  //   setError({
  //     status: true,
  //     message: "Username cannot start with numbers",
  //   });
  //   return;
  // }

  // if (!lowercaseCharactersValidator(usernameValue)) {
  //   setError({
  //     status: true,
  //     message: "Only alpha-numeric characters are allowed",
  //   });
  //   return;
  // }

  if (usernameValue.length < 3) {
    setError({
      status: true,
      message: "Username must be at least 3 characters",
    });
    return;
  }

  setError({
    status: false,
    message: "",
  });
};

export const checkUsernameUniqueness = async (usernameValue, __BaseURL, founder) => {
  const requestURLBase = `${__BaseURL}founders/${founder}`;

  try {
    const res = await fetch(`${requestURLBase}/check-username`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: usernameValue, founderName: founder }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(data.message);
    }

    return {
      status: true,
      message: "",
    };
  } catch (err) {
    console.error("~ validators.js ~ checkUsernameUniqueness(): " + err);

    return {
      status: false,
      message: err,
    };
  }
};
