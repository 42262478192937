export const entryPoint = "0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789";
export const factory = "0x2bf9454c08bb13dc52a90ec2702454bdd39a0a32";
export const AlchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
export const rpcUrl = `https://polygon-mainnet.g.alchemy.com/v2/${AlchemyKey}`;
export const fixedNonce = 1;
// export const LyncNFTAddress = "0x572A9dE8235863fc59F2db87B8baFDF105ba3F69";
export const ImplementationContractAddress =
  "0x264478e9aeb659c877dc6df748409d6a44f4793b";
export const vish = "0x7C9A85750dA731fd754FA38DA1D3f5397aDE947A";
export const shanu = "0x948201F61e3117F2C1824070BeDa93f388A3e151";
export const explorerURL = "https://polygonscan.com/address/";
export const openSeaURL = "https://opensea.io/";
export const raribleURL = "https://rarible.com/user/";
