import React from "react";
import { Authentication } from "./components/Authentication";

export const AuthenticationForVish = () => {
  return <Authentication founder="vish" />;
};

export const AuthenticationForShanu = () => {
  return <Authentication founder="shanu" />;
};
