import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce, useEnv, useFormErrorState } from "../hooks";
import { checkUsernameUniqueness, validateEmail, validateUsername } from "../utils/validators";
import { MdCheckCircle } from "react-icons/md";
import { loginPasswordLess, registerPasswordLess } from "../utils/authentications";
import { Spinner } from "./Spinner";

export const Authentication = ({ founder }) => {
  const __BaseURL = useEnv("SERVER_URL");
  const navigate = useNavigate();
  const authenticationFormRef = React.useRef(null);
  const usernameInputRef = React.useRef(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("action");

  const debouncedUsernameValue = useDebounce(usernameInputRef.current?.value || "");

  const { error: usernameError, setError: setUsernameError } = useFormErrorState();
  const { error: emailError, setError: setEmailError } = useFormErrorState();

  const [action, setAction] = React.useState(undefined);
  const [formSubmitPending, setFormSubmitPending] = React.useState(false);

  const validateEmailInput = (event) => {
    const inputElement = event.target;
    validateEmail(inputElement.value, setEmailError);
  };

  const isUniqueUsername = () => {
    if (action === "register") {
      checkUsernameUniqueness(debouncedUsernameValue, __BaseURL, founder)
        .then((data) => {
          console.log(data);
          if (!data.status) {
            setUsernameError({
              status: true,
              message: "Username not available.",
            });
          }
        })
        .catch((err) => {
          console.log("Error in isUniqueUsername(): ", err);
        });
    }
  };

  const validateUsernameInput = (event) => {
    const inputElement = event.target;
    console.log(event);

    if (event.type === "blur") {
      isUniqueUsername();
    }
    validateUsername(inputElement.value, setUsernameError);
  };

  const changeQueryAction = () => {
    if (authenticationFormRef.current) {
      authenticationFormRef.current.reset();
      setUsernameError({ status: undefined, message: "" });
      setEmailError({ status: undefined, message: "" });
    }

    if (query === "register") {
      navigate(".?action=login", { replace: true });
    } else {
      navigate(".?action=register", { replace: true });
    }
  };

  const formSubmitAction = async (event) => {
    event.preventDefault();
    const form = event.target;

    if (usernameError.status || emailError.status) {
      return;
    }

    if (action === "register") {
      if (!form.userName.value || !form.userEmail.value) {
        validateUsername(form.userName.value, setUsernameError);
        validateEmail(form.userEmail.value, setEmailError);

        return;
      }

      setFormSubmitPending(true);
      await registerPasswordLess(event, __BaseURL, navigate, founder);
      setFormSubmitPending(false);
    } else {
      if (!form.userName.value) {
        validateUsername(form.userName.value, setUsernameError);
        return;
      }

      setFormSubmitPending(true);
      await loginPasswordLess(event, __BaseURL, navigate, founder);
      setFormSubmitPending(false);
    }
  };

  React.useEffect(() => {
    if (query === null || (query !== "register" && query !== "login")) {
      navigate(".?action=register", { replace: true });
      setAction("register");
    }

    if (query) {
      setAction(query);
    } else {
      setAction(undefined);
    }
  }, [query]);

  console.log(debouncedUsernameValue);

  React.useEffect(() => {
    if (!debouncedUsernameValue) {
      return;
    }

    isUniqueUsername();
  }, [debouncedUsernameValue, founder]);

  if (action === undefined) {
    return <Spinner />;
  }

  return (
    <form ref={authenticationFormRef} className="registrationForm" noValidate onSubmit={formSubmitAction}>
      <div className="formControlGroup">
        <div className="formControlWrapper">
          <input
            ref={usernameInputRef}
            type="text"
            name="userName"
            id="userName"
            className={`formControl${usernameError.status ? " controlError" : ""}`}
            required
            placeholder="eg.- hellofounders"
            onInput={validateUsernameInput}
            onBlur={validateUsernameInput}
          />
          <label htmlFor="userName" className="controlLabel">
            Choose Username
          </label>
          {usernameError.status === false ? (
            <span className="checkIcon">
              <MdCheckCircle />
            </span>
          ) : (
            <></>
          )}
        </div>
        <p className="formControlErrorMsg">{usernameError.message}</p>
      </div>
      {action === "register" ? (
        <React.Fragment>
          <div className="formControlGroup">
            <input
              type="email"
              name="userEmail"
              id="userEmail"
              className={`formControl${emailError.status ? " controlError" : ""}`}
              required
              placeholder="name@example.com"
              onInput={validateEmailInput}
              onBlur={validateEmailInput}
            />
            <label htmlFor="userEmail" className="controlLabel">
              Enter Your Email
            </label>
            <p className="formControlErrorMsg">{emailError.message}</p>
          </div>
          <div className="formControlGroup">
            <input type="text" name="organizationName" id="organizationName" className="formControl" placeholder="eg.- Lync" />
            <label htmlFor="organizationName" className="controlLabel">
              Your Organization Name
            </label>
            <p className="formControlErrorMsg"></p>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <button type="submit" className="formSubmitButton">
        {formSubmitPending ? "Please wait..." : action === "register" ? "Register" : "Login"}
      </button>
      <button onClick={changeQueryAction} type="button" className="authenticationToggleButton">
        {action === "register" ? "Already have an account?" : "Create an account?"}
      </button>
    </form>
  );
};
