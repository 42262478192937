import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  AuthenticationForShanu,
  AuthenticationForVish,
} from "./AuthenticationPages";
import { MintPage } from "./MintPage";
import "./styles/main.css";

export const App = () => {
  return (
    <main className="app">
      <Routes>
        <Route path="/" element={<AuthenticationForVish />} />
        <Route path="vish" element={<AuthenticationForVish />} />
        <Route path="shanu" element={<AuthenticationForShanu />} />
        <Route path="shanu/mint" element={<MintPage founder="shanu" />} />
        <Route path="vish/mint" element={<MintPage founder="vish" />} />
        <Route path="*" element={<AuthenticationForVish />} />
      </Routes>
    </main>
  );
};
