import React from "react";

export const useFormErrorState = (
  initialState = {
    status: undefined,
    message: "",
  }
) => {
  const [error, setError] = React.useState(initialState);
  return { error, setError };
};
