import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-hot-toast";
import {
  rpcUrl,
  factory,
  fixedNonce,
  entryPoint,
  LyncNFTAddress,
  ImplementationContractAddress,
  vish,
  shanu,
  explorerURL,
  openSeaURL,
  raribleURL,
} from "./config.js";
import FactoryAbi from "./abi/Factory.json";
import ACCOUNT_ABI from "./abi/PassKeyAccount.json";
import NFT_ABI from "./abi/LyncPasskeyNFT.json";

import { ethers } from "ethers";
import getXandY from "./helper/getPubXandY.js";
import getEncodedSignature from "./helper/getEncodedSignatre.js";
import passLessVerification from "./helper/passLessVerification.js";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "./components/Modal.js";
const initialState = {
  provider: null,
  factoryContract: null,
  smartWalletAddress: null,
  isDeploying: false,
  isLoading: true,
  publicKey: null,
  keyId: null,
  explore: null,
  publicX: null,
  publicY: null,
  isContractDeployed: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        isLoading: false,
        provider: action.payload.provider,
        factoryContract: action.payload.factory,
        smartWalletAddress: action.payload.smartWalletAddress,
        publicKey: action.payload.publicKey,
        keyId: action.payload.keyId,
        publicX: action.payload.publicX,
        publicY: action.payload.publicY,
        isContractDeployed: action.payload.isContractDeployed,
      };
    case "SET_LOADING_FALSE":
      return {
        ...state,
        isLoading: false,
      };
    case "SET_DEPLOYING_TRUE":
      return {
        ...state,
        isDeploying: true,
      };
    case "SET_DEPLOYING_FALSE":
      return {
        ...state,
        isDeploying: false,
      };
    case "SET_EXPLORE":
      return {
        ...state,
        explore: action.payload.explore,
      };
    default:
      return state;
  }
};
export const MintPage = ({ founder }) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const login = location.state?.login;
  const [closeModal, setCloseModal] = React.useState(false);

  const [data, dispatch] = useReducer(reducer, initialState);
  console.log("data", data);
  const {
    isLoading,
    isDeploying,
    smartWalletAddress,
    publicKey,
    keyId,
    provider,
    factoryContract,
    explore,
    publicX,
    publicY,
    isContractDeployed,
  } = data;
  async function getSmartWalletAddress() {
    const provider = new ethers.getDefaultProvider(rpcUrl);
    const FactoryContract = new ethers.Contract(factory, FactoryAbi, provider);
    console.log("FactoryContract", FactoryContract);
    try {
      const res = await fetch(
        `${`${process.env.REACT_APP_SERVER_URL}founders/${founder}/get-user-detail?username=${login.userName}&credentialId=${login.credentialID}&founderName=${founder}`}`
      );
      const resData = await res.json();
      console.log("asdfsda", res, resData);
      if (res.ok) {
        const { x, y } = await getXandY(resData.publicKey);
        console.log(x, y);
        const smartWalletAddress = await FactoryContract.getAddress(
          fixedNonce,
          resData?.keyId,
          x,
          y
        );
        dispatch({
          type: "SET_DATA",
          payload: {
            provider,
            factory: FactoryContract,
            smartWalletAddress,
            publicKey: resData.publicKey,
            keyId: resData.keyId,
            publicX: x,
            publicY: y,
            isContractDeployed: resData.smartAccountAddress ? true : false,
          },
        });
        console.log(smartWalletAddress);
      } else {
        toast.error(resData.message);
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
    } finally {
      dispatch({ type: "SET_LOADING_FALSE" });
    }
  }

  async function deplySmartAccount() {
    dispatch({ type: "SET_DEPLOYING_TRUE" });
    // check if contract exists on smartWalletAddress using ethers
    const exists = await provider.getCode(smartWalletAddress);
    const AccountContract = new ethers.Contract(
      ImplementationContractAddress,
      ACCOUNT_ABI,
      provider
    );
    let smartContractAddress;
    if (founder === "shanu") {
      smartContractAddress = shanu;
    } else if (founder === "vish") {
      smartContractAddress = vish;
    } else {
      toast.error("Something went wrong, Founder is not valid");
      return;
    }
    const NFTContract = new ethers.Contract(
      smartContractAddress,
      NFT_ABI,
      provider
    );
    console.log(exists);

    if (exists === "0x") {
      //if (true) {
      try {
        const { signature, authenticatorData, challenge, client, clientData } =
          await passLessVerification(login.userName, founder);
        const encodedSig = await getEncodedSignature(
          signature,
          authenticatorData,
          challenge,
          keyId,
          client,
          clientData
        );

        let initCode = ethers.utils.hexConcat([
          factory,
          factoryContract.interface.encodeFunctionData("createAccount", [
            String(fixedNonce),
            keyId,
            publicX,
            publicY,
          ]),
        ]);
        console.log("initCode: ", initCode);

        const timestamp = Math.floor(Date.now() / 1000);
        const mintNonce = timestamp; // need to add more randomness - @todo
        const signingAccount = new ethers.Wallet(process.env.REACT_APP_PVT_KEY);

        const hash = await NFTContract.getHash(mintNonce);
        const mintSig = await signingAccount.signMessage(
          ethers.utils.arrayify(hash)
        );

        const mintNFTData = NFTContract.interface.encodeFunctionData(
          "safeMint",
          [smartWalletAddress, mintSig, mintNonce]
        );
        const encodedCall = AccountContract.interface.encodeFunctionData(
          "execute",
          [smartContractAddress, ethers.BigNumber.from(0), mintNFTData]
        );
        const optionsPaymaster = {
          method: "POST",
          headers: {
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            id: 1,
            jsonrpc: "2.0",
            method: "alchemy_requestGasAndPaymasterAndData",
            params: [
              {
                policyId:
                  process.env.REACT_APP_ALCHEMY_POLICY_ID ||
                  "bf618afe-f983-4087-a9ad-3f7d9b054ef0",
                entryPoint: entryPoint,
                dummySignature: encodedSig,
                userOperation: {
                  sender: smartWalletAddress,
                  nonce: "0x0",
                  initCode: initCode,
                  callData: encodedCall,
                },
              },
            ],
          }),
        };

        const respaymaster = await fetch(rpcUrl, optionsPaymaster);
        const respaymasterData = await respaymaster.json();
        console.log("respaymasterData: ", respaymasterData);

        let op = {
          sender: smartWalletAddress,
          nonce: "0x0",
          initCode: initCode,
          callData: encodedCall,
          maxFeePerGas: respaymasterData.result.maxFeePerGas,
          maxPriorityFeePerGas: respaymasterData.result.maxPriorityFeePerGas,
          signature: encodedSig,
        };

        op.callGasLimit = respaymasterData.result.callGasLimit;
        op.verificationGasLimit = respaymasterData.result.verificationGasLimit;
        op.preVerificationGas = respaymasterData.result.preVerificationGas;
        op.paymasterAndData = respaymasterData.result.paymasterAndData;
        console.log("op: ", op);
        const options1 = {
          method: "POST",
          headers: {
            accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            id: 1,
            jsonrpc: "2.0",
            method: "eth_sendUserOperation",
            params: [op, entryPoint],
          }),
        };

        const alachemyresp = await fetch(rpcUrl, options1);
        const alachemyrespData = await alachemyresp.json();
        console.log("alachemyrespData: ", alachemyrespData);
        if (alachemyrespData.result) {
          const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}founders/${founder}/update-contract-information`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                credentialId: login.credentialID,
                contractAddress: smartWalletAddress,
              }),
            }
          );
          const resData = await res.json();
          if (res.ok) {
            console.log(resData.message);
          } else {
            console.log(resData);
          }
          toast.success("Smart Account Deployed Successfully and NFT Minted");
          dispatch({
            type: "SET_EXPLORE",
            payload: { explore: alachemyrespData.result },
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      } finally {
        dispatch({ type: "SET_DEPLOYING_FALSE" });
      }
    } else {
      toast.error("Smart Account already deployed");
      dispatch({ type: "SET_DEPLOYING_FALSE" });
      return;
    }

    // setDeploying(false);
  }

  useEffect(() => {
    if (!login || !login.userName || !login.credentialID) {
      navigate(`/${founder}`);
      return;
    }

    getSmartWalletAddress();
  }, [login]);

  return (
    <main className="app mintPage">
      <section className="nftImageContainer">
        <div className="nftImageBg jsScroll scrolled zoomIn"></div>
        <span className="nftImageGroup">
          <img
            src={founder === "shanu" ? "/shanuNFT.png" : "/vishNFT.png"}
            alt="founder"
            className="nftImage jsScroll scrolled fadeInTop"
          />
          <img
            src="/nftBasePatch.png"
            alt="founder"
            className="nftPatchImage jsScroll scrolled spreadOut"
          />
        </span>
      </section>
      <section className="founderDetailsContainer">
        <div className="founderDetailsEyebrow jsScroll scrolled slideInLeft">
          Mint the Founder
        </div>
        <h1 className="founderName jsScroll scrolled slideInLeft">
          {founder === "shanu" ? "Shanu Joshi" : "Vishwas Bhushan"}
        </h1>
        <h2 className="founderDesignation jsScroll scrolled slideInLeft">
          Co-Founder at LYNC
        </h2>
      </section>
      <section className="connectDetails">
        <span className="connectDetailsEyebrow jsScroll scrolled slideInLeft">
          get connected
        </span>
        <div className="connectDetailsDescription">
          <div className="connectDetailsDescriptionText jsScroll scrolled slideInLeft">
            <span className="descriptionTextEyebrow">Let&apos;s</span>
            <span className="descriptionText">LYNC on Social</span>
          </div>
          <div className="connectDetailsIcons jsScroll scrolled slideInRight">
            <a
              href={`https://twitter.com/${
                founder === "shanu" ? "Shanu12Joshi" : "BhushanVishwas"
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="connectionIcons twitterIcon"
            >
              <img src="/twitter.svg" alt="twitter" />
            </a>
            <a
              href={`https://t.me/${
                founder === "shanu" ? "shanu12joshi" : "vishnft"
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="connectionIcons telegramIcon"
            >
              <img src="/telegram.svg" alt="telegram" />
            </a>
            <a
              href={`https://www.linkedin.com/in/${
                founder === "shanu" ? "shanu12joshi/" : "vishwasbhushan/"
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="connectionIcons linkedinIcon"
            >
              <img src="/linkedin.svg" alt="linkedin" />
            </a>
          </div>
        </div>
      </section>
      {smartWalletAddress && !isLoading ? (
        <>
          <div className="smartAccountInformationContainer">
            <p title={smartWalletAddress} className="smartAccountAddress">
              {`${smartWalletAddress.substring(
                0,
                6
              )}...${smartWalletAddress.slice(-5)}`}
            </p>
            <a
              className="smartAccountExplore"
              href={`${explorerURL}${smartWalletAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              view smart wallet address on explorer
            </a>
          </div>
          {explore &&
            (!closeModal ? (
              <Modal
                onClose={() => setCloseModal(true)}
                founder={founder}
                polygonLink={`${explorerURL}${smartWalletAddress}`}
                openseaLink={openSeaURL + smartWalletAddress}
                raribleLink={raribleURL + smartWalletAddress}
              />
            ) : (
              <React.Fragment></React.Fragment>
            ))}
          {!explore ? (
            <>
              {isContractDeployed ? (
                <a
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                  target="_blank"
                  className="mintActionButton jsScroll scrolled fadeInBottom"
                  href={raribleURL + smartWalletAddress}
                >
                  View NFT
                </a>
              ) : (
                <button
                  className="mintActionButton jsScroll scrolled fadeInBottom"
                  onClick={() => deplySmartAccount()}
                  disabled={isDeploying}
                >
                  {isDeploying
                    ? "Deploying Smart Account and Minting NFT..."
                    : " Deploy Smart Account And Mint NFT"}
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <React.Fragment>
          <div className="smartAccountInformationContainer"></div>
          <button
            disabled
            className="mintActionButton jsScroll scrolled fadeInBottom"
          >
            Creating Smart Account...
          </button>
        </React.Fragment>
      )}
    </main>
  );
};
