import React from "react";
import Confetti from "react-confetti";

export default function Modal({ founder, polygonLink, openseaLink, raribleLink, onClose }) {
  return (
    <React.Fragment>
      <div className="modalOverlay">
        <Confetti friction={1} width={window.innerWidth || 300} height={window.innerHeight || 200} />
        <div className="modalContainer">
          <button className="modalCloseAction" onClick={onClose}>
            <img src="/cross.svg" alt="close" />
          </button>
          <span className="happyNftImageContainer">
            <img src={founder === "shanu" ? "/happyShanu.png" : "/happyVish.png"} alt="happyFounder" />
          </span>
          <div className="congratulationsMsgContainer">
            <span className="congratulationsLabel">Congratulations !!</span>
            <p className="congratulationsMsg">You have successfully minted LYNC Co-Founder as NFT using LYNC AA ^^</p>
          </div>
          <div className="actionButtonsContainer">
            <a
            href="https://twitter.com/intent/tweet?text=I+just+minted+one+of+the+%40lyncworld+Co-Founder+as+an+NFT+on+%400xPolygon+with+my+biometric+id+%F0%9F%A4%A9%F0%9F%9A%80+%0D%0A%0D%0AWeb3%27s+billion-user+milestone+is+coming%21+%23LYNC+%23token2049+%23Web3Gaming"
            target="_blank"
              rel="noreferrer"
              data-size="large"
              className="actionButtons twitterShareButton"
            >
              <span className="twitterSVG">
                <img src="/twitter.svg" alt="twitter" />
              </span>
              tweet it
            </a>
            <a href={raribleLink} target="_blank" rel="noopener noreferrer" className="actionButtons viewNftButton">
              View NFT
            </a>
          </div>

          <a href={polygonLink} target="_blank" rel="noopener noreferrer" className="explorerLinkContainer">
            <span className="polygonScanSVG">
              <img src="/polygonScan.svg" alt="polygon" />
            </span>
            view on polygonScan
          </a>

          <div className="linkIconsContainer">
            <a href={openseaLink} target="_blank" rel="noopener noreferrer" className="linkIcons">
              <img src="/opensea.svg" alt="opensea" />
            </a>
            <a style={{ marginLeft: "1.1rem" }} href={raribleLink} target="_blank" rel="noopener noreferrer" className="linkIcons">
              <img src="/rarible.svg" alt="rarible" />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
