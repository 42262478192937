import { client, parsers } from "@passwordless-id/webauthn";

async function passLessVerification(userName, founder) {
  const serverUrl = `${process.env.REACT_APP_SERVER_URL}founders/${founder}/`;
  try {
    const res = await fetch(`${serverUrl}login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: userName }),
    });
    const resData = await res.json();
    if (res.ok) {
      console.log(res, resData);

      const challenge = resData.challenge;
      console.log(challenge);
      const authentication = await client.authenticate(
        [resData.message.credentialID],
        challenge,
        {
          authenticatorType: "auto",
          userVerification: "required",
          timeout: 60000,
        }
      );
      console.log(authentication);
      const x = parsers.parseAuthentication(authentication);
      console.log("x", x);
      if (authentication) {
        const verifyRes = await fetch(`${serverUrl}verify-login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ authentication }),
        });
        const verifyResData = await verifyRes.json();
        console.log(verifyRes, verifyResData);
        if (verifyRes.ok) {
          return {
            signature: verifyResData.message.signature,
            authenticatorData: authentication.authenticatorData,
            challenge: challenge,
            client: x.client,
            clientData: authentication.clientData,
          };
        } else {
          throw Error("Verification failed");
        }
      } else {
        throw Error("Verification failed");
      }
    } else {
      console.log(resData.message);
      throw Error(resData.message);
    }
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
}

export default passLessVerification;
