import React from "react";

export const useDebounce = (value, delay = 250) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, [delay]);

    return () => clearTimeout(timeoutId);
  }, [value, delay]);

  return debouncedValue;
};
